

import React from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";

const ResetPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    retypePassword: '',
    type:''
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().min(4).max(50).required("Old Password is a required field"),
    newPassword: yup.string().min(4).max(50).required("New Password is a required field"),
    retypePassword: yup.string().oneOf([yup.ref('newPassword'), null], "Passwords must match").required("Retype Password is a required field"),
  });

  const { handleSubmit, handleChange, values, errors, touched ,setFieldValue} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (formValues, { setSubmitting }) => {
      const { retypePassword, ...data } = formValues; // Exclude retypePassword
      try {
        const response = await apiAdminConfig.put(`auth/reset-password`, data);

        if (response?.status === 200) {
          navigate('/');
          toast({ message: 'Password Reset successfully', bg: 'success' });
        } else {
          toast({ message: response?.data?.message, bg: 'danger' });
        }
      } catch (error) {
        const { response } = error;
        toast({ message: response?.data?.message, bg: "danger" });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handlePasswordChange = (passwordType) => {
    setFieldValue('type',passwordType)
  };

  return (
    <main id="events">
      <Container className="admin_padding">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="table-header-container">
          <h3 className="table-header-name">PASSWORD RESET</h3>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">FILL PASSWORDS</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormikOldPassword">
                      <Form.Label>Old Password <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="password"
                        name="oldPassword"
                        placeholder="Enter Old Password"
                        value={values.oldPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.oldPassword && touched.oldPassword}
                      />
                      <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormikNewPassword">
                      <Form.Label>New Password<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="password"
                        name="newPassword"
                        placeholder="Enter New Password"
                        value={values.newPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.newPassword && touched.newPassword}
                      />
                      <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormikRetypePassword">
                      <Form.Label>ReType New Password <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="password"
                        name="retypePassword"
                        placeholder="Enter Retype Password"
                        value={values.retypePassword}
                        onChange={handleChange}
                        isInvalid={!!errors.retypePassword && touched.retypePassword}
                      />
                      <Form.Control.Feedback type="invalid">{errors.retypePassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ display: "flex", justifyContent: "space-between" }} className="footer-btns">
                  <Button type="submit" className="input_file_button" style={{marginRight:"10px"}} onClick={() => handlePasswordChange('password')}>
                    CHANGE LOGIN PASSWORD
                  </Button>

                  <Button type="submit" className=" report-pass-btn" style={{marginRight:"10px"}} onClick={() => handlePasswordChange('reportPassword')}>
                    CHANGE REPORT PASSWORD
                  </Button>

                  <Button type="submit" className=" transaction-pass-btn" style={{marginRight:"10px", backgroundColor:"#ff003b",border:"none"}} onClick={() => handlePasswordChange('transactionsPassword')}>
                    CHANGE TRANSACTION PASSWORD
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default ResetPassword;
