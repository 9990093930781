import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Outlet, Link, useNavigate } from "react-router-dom";
// import { HomeOutlined, UserAddOutlined, TeamOutlined, FileTextOutlined, AuditOutlined, TransactionOutlined } from '@ant-design/icons';
import {
  UserOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  UserAddOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
  DownOutlined,
  CaretDownOutlined,
  FileTextOutlined,
  AuditOutlined,
  AppstoreOutlined 

} from "@ant-design/icons";
import { Menu, Layout, Avatar, Button, Popover, Select } from "antd";
import { useAuth } from "../Component/hook/auth";
import { CloseOutlined } from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

function Sidebar() {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 767);
  const [isWindowShow, setIsWindowShow] = useState(window.innerWidth >= 767);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const { logout } = useAuth();

  const navigateTo = useNavigate();

  const data = localStorage.getItem('userDetail');
  const userData = JSON.parse(data);
  const userRole = userData?.role;
  const userName = userData?.userName?.charAt(0)?.toUpperCase() + userData?.userName?.slice(1).toLowerCase();

  useEffect(() => {
    const handleResize = () => {
      setIsWindowShow(window.innerWidth >= 767)
      setCollapsed(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleSubMenuClick = (key) => {
    setOpenSubMenu(openSubMenu === key ? null : key); // Toggle the submenu open/close
  };

  const toggleCollapsedMobile = () => {
    if (window.innerWidth <= 767) {
      setCollapsed(!collapsed);
    }
  }

  console.log('isWindowShow', isWindowShow);

  const content = (
    <div>
      <Button className="admin-topbar-btn" onClick={() => navigateTo('/WinLucky/reset-password')}>
        {/* <UserOutlined /> */}
        Change Password
      </Button>

      <Button className="admin-topbar-btn" onClick={logout}>
        {/* <LogoutOutlined /> */}
        LogOut
      </Button>
    </div>
  );

  const iconStyle = {
    color: '#ffffff',
    backgroundColor: '#0033cc',
    borderRadius: '20%',
    padding: '4px',
    fontSize: '12px'
  };


  const onOpenChange = (openKeys) => {
    setOpenSubMenu(openSubMenu === openKeys[1] ? null : openKeys[1])
  }


  return (
    <>

      {
        collapsed ?


          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              width={0}
              style={{
                position: "fixed",
                height: "100vh",
                zIndex: "999",
                left: "0",
              }}
            >
              {isWindowShow &&
                <button
                  title="Toggle Menu"
                  className="sidebarWrapperButton"
                  onClick={toggleCollapsed}
                >
                  <span></span>
                  <span></span>
                  {/* <span></span> */}
                </button>
              }

              <div className="sidebarLogo">
                {isWindowShow ? <h4>GOA ADMIN</h4> : <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>{userName}</h4>}
              </div>

              <Menu mode="inline"
                openKeys={[openSubMenu]}
                onOpenChange={onOpenChange}>
                <Menu.Item key="Dashboard" icon={<AppstoreOutlined  style={iconStyle} onClick={() => setOpenSubMenu(null)} />}>
                  <span>DASHBOARD</span>
                  <Link to="/" />
                </Menu.Item>

                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Add-Point-Admin" icon={<MenuFoldOutlined style={iconStyle} />}>
                      <span>CREATE POINT</span>
                      <Link to="WinLucky/add-point" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>
                  </>
                )}

                <Menu.SubMenu title="CREATE MEMBER" key="crate-member" icon={<UserAddOutlined style={iconStyle} onClick={() => handleSubMenuClick('create-member')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Create-Register" >
                      <span>Admin</span>
                      <Link to="/WinLucky/admin/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Partner" >
                      <span>Partner</span>
                      <Link to="/WinLucky/partner/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Distributor" >
                      <span>Distributor</span>
                      <Link to="/WinLucky/distributor/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Sub Distributor" >
                      <span>Sub Distributor</span>
                      <Link to="/WinLucky/sub-distributor/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Agent" >
                      <span>Agent</span>
                      <Link to="/WinLucky/agent/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-User" >
                      <span>User</span>
                      <Link to="/WinLucky/user/form" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                <Menu.SubMenu title="MEMBER LIST" key="menu" icon={<FileTextOutlined style={iconStyle} onClick={() => handleSubMenuClick('menu')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Register" >
                      <span>Admin</span>
                      <Link to="/WinLucky/admin" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Partner" >
                      <span>Partner</span>
                      <Link to="/WinLucky/partner" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Distributor" >
                      <span>Distributor</span>
                      <Link to="/WinLucky/distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Sub Distributor" >
                      <span>Sub Distributor</span>
                      <Link to="/WinLucky/sub-distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Agent" >
                      <span>Agent</span>
                      <Link to="/WinLucky/agent" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="User" >
                      <span>User</span>
                      <Link to="/WinLucky/user" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINT MANAGEMENT" key="pointMenu" icon={<AuditOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="PartnerPoint" >
                        <span>Partner</span>
                        <Link to="/WinLucky/partner/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="DistributorPoint" >
                        <span>Distributor</span>
                        <Link to="/WinLucky/distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Sub Distributor Point" >
                        <span>Sub Distributor</span>
                        <Link to="/WinLucky/sub-distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="AgentPoint" >
                        <span>Agent</span>
                        <Link to="/WinLucky/agent/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="UserPoint" >
                        <span>User</span>
                        <Link to="/WinLucky/user/point" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}



                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINTS TRANSACTION" key="poinTransaction" icon={<MenuFoldOutlined style={iconStyle} />}>
                    <Menu.Item key="PartnerPointin">
                      <span>Transaction In</span>
                      <Link to="/WinLucky/transaction-in" />
                    </Menu.Item>
                    <Menu.Item key="PartnerPointout" >
                      <span>Transaction Out</span>
                      <Link to="/WinLucky/transaction-out" />
                    </Menu.Item>
                    <Menu.Item key="PointFile" >
                      <span>Point File </span>
                      <Link to="/WinLucky/point-file" />
                    </Menu.Item>
                  </Menu.SubMenu>
                )}

                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="BUSSINESS REPORT" key="bussinessMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="BussinessPartner" >
                        <span>Partner</span>
                        <Link to="/WinLucky/partner/bussiness-report" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="BussinessDistributor" >
                        <span>Distributor</span>
                        <Link to="/WinLucky/distributor/bussiness-report" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Bussiness Sub Distributor" >
                        <span>Sub Distributor</span>
                        <Link to="/WinLucky/sub-distributor/bussiness-report" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="BussinessAgent" >
                        <span>Agent</span>
                        <Link to="/WinLucky/agent/bussiness-report" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="BussinessUser" >
                        <span>User</span>
                        <Link to="/WinLucky/user/bussiness-report" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}

                {/* {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <>
                    <Menu.Item key="BussinessReport" icon={<HomeOutlined style={iconStyle} />}>
                      <span>BUSSINESS REPORT </span>
                      <Link to="/bussiness-report" />
                    </Menu.Item>

                  </>
                )} */}
                {/* {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINT MANAGEMENT" key="pointMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="PartnerPoint" >
                        <span>Partner</span>
                        <Link to="/partner/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="DistributorPoint" >
                        <span>Distributor</span>
                        <Link to="/distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Sub Distributor Point" >
                        <span>Sub Distributor</span>
                        <Link to="/sub-distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="AgentPoint" >
                        <span>Agent</span>
                        <Link to="/agent/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="UserPoint" >
                        <span>User</span>
                        <Link to="/user/point" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )} */}

                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <>
                    <Menu.Item key="GameTransaction" icon={<MenuFoldOutlined style={iconStyle} />}>
                      <span>GAME TRANSACTION</span>
                      <Link to="/WinLucky/game-transaction" />
                    </Menu.Item>

                  </>
                )}

                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Game" icon={<AuditOutlined style={iconStyle} />}>
                      <span>GAME</span>
                      <Link to="/WinLucky/game" />
                    </Menu.Item>
                    <Menu.Item key="Add-Point-Admin" icon={<HomeOutlined style={iconStyle} />}>
                      <span>ADD POINT</span>
                      <Link to="WinLucky/add-point" />
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>

            {
              isWindowShow
                ?
                <Layout style={{ marginLeft: 0 }}>
                  <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                    <div className="topbar">
                      <div className="topbarWrapper d-flex justify-content-end">
                        <div className="topRight">
                          <Popover content={content} trigger={['hover', 'click']} placement="bottom">
                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                              <div className="topbar-text">
                                <h6 style={{ fontWeight: '200' }}>{userRole?.toUpperCase()}</h6>
                              </div>
                              <div className="topbarIcons" style={{ marginLeft: 8 }}>
                                <CaretDownOutlined />
                              </div>
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </Header>

                  <Content style={{ marginTop: 60 }}>
                    <Outlet />
                  </Content>
                </Layout>
                :
                <Layout style={{ marginLeft: 0 }}>
                  <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                    <div className="topbar" style={{ height: '100px' }}>
                      <div>
                        <div className="d-flex justify-content-between align-items-center" style={{ paddingRight: '20px', paddingLeft: '20px', borderBottom: '1px solid rgb(233 233 233)' }}>
                          <h4 style={{ margin: 0, fontWeight: 600 }}>GOALOCAL</h4>
                          <button
                            title="Toggle Menu"
                            className="sidebarWrapperBtn"
                            onClick={toggleCollapsed}
                          >
                            <span style={{ height: '2px' }}></span>
                            <span style={{ height: '2px' }}></span>
                          </button>
                        </div>
                        <div className="topbarWrapper d-flex justify-content-end" style={{ lineHeight: 0, paddingRight: '70px' }}>
                          <div className="topRight">
                            <Popover content={content} trigger={['hover', 'click']} placement="bottom">
                              <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <div className="topbar-text">
                                  <h6 style={{ fontWeight: '200' }}>{userRole?.toUpperCase()}</h6>
                                </div>
                                <div className="topbarIcons">
                                  <CaretDownOutlined />
                                </div>
                              </div>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Header>

                  <Content style={{ marginTop: 100 }}>
                    <Outlet />
                  </Content>
                </Layout>
            }

          </Layout>
          :
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              width={240}
              style={{
                position: "fixed",
                height: "100vh",
                zIndex: "999",
                left: "240",
              }}
            >
              {
                isWindowShow &&
                <button
                  title="Toggle Menu"
                  className="sidebarWrapperButton"
                  onClick={toggleCollapsed}
                >
                  <span></span>
                  <span></span>
                  {/* <span></span> */}
                </button>
              }

              <div className="sidebarLogo d-flex justify-content-between align-items-center">

                {/* <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>GOA ADMIN</h4> */}
                {isWindowShow ? <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>GOA ADMIN</h4> : <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>{userName}</h4>}
                {/* {
                  !isWindowShow
                  &&
                  <button
                    title="Toggle Menu"
                    onClick={toggleCollapsed}
                    
                    className="close-button"
                  >

                    <CloseOutlined style={{ color: "rgb(0, 0, 0)", paddingRight: "10px", fontSize: "20px" }} />

                    
                  </button>
                } */}
              </div>

              <Menu mode="inline"
                openKeys={[openSubMenu]}
                onOpenChange={onOpenChange}>
                <Menu.Item key="Dashboard" icon={<AppstoreOutlined  style={iconStyle} onClick={() => setOpenSubMenu(null)} />}>
                  <span>DASHBOARD</span>
                  <Link to="/" onClick={() => toggleCollapsedMobile()} />
                </Menu.Item>

                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Add-Point-Admin" icon={<MenuFoldOutlined style={iconStyle} />}>
                      <span>CREATE POINT</span>
                      <Link to="WinLucky/add-point" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>
                  </>
                )}

                <Menu.SubMenu title="CREATE MEMBER" key="crate-member" icon={<MenuFoldOutlined style={iconStyle} onClick={() => handleSubMenuClick('create-member')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Create-Register" >
                      <span>Admin</span>
                      <Link to="/WinLucky/admin/form" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Partner" >
                      <span>Partner</span>
                      <Link to="/WinLucky/partner/form"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Distributor" >
                      <span>Distributor</span>
                      <Link to="/WinLucky/distributor/form"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Sub Distributor" >
                      <span>Sub Distributor</span>
                      <Link to="/WinLucky/sub-distributor/form"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Agent" >
                      <span>Agent</span>
                      <Link to="/WinLucky/agent/form"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-User" >
                      <span>User</span>
                      <Link to="/WinLucky/user/form"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                <Menu.SubMenu title="MEMBER LIST" key="menu" icon={<MenuFoldOutlined style={iconStyle} onClick={() => handleSubMenuClick('menu')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Register" >
                      <span>Admin</span>
                      <Link to="/WinLucky/admin"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Partner" >
                      <span>Partner</span>
                      <Link to="/WinLucky/partner"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Distributor" >
                      <span>Distributor</span>
                      <Link to="/WinLucky/distributor"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Sub Distributor" >
                      <span>Sub Distributor</span>
                      <Link to="/WinLucky/sub-distributor"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Agent" >
                      <span>Agent</span>
                      <Link to="/WinLucky/agent"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="User" >
                      <span>User</span>
                      <Link to="/WinLucky/user"
                        onClick={() => toggleCollapsedMobile()}
                      />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINT MANAGEMENT" key="pointMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="PartnerPoint" >
                        <span>Partner</span>
                        <Link to="/WinLucky/partner/point"
                          onClick={() => toggleCollapsedMobile()}
                        />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="DistributorPoint" >
                        <span>Distributor</span>
                        <Link to="/WinLucky/distributor/point" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Sub Distributor Point" >
                        <span>Sub Distributor</span>
                        <Link to="/WinLucky/sub-distributor/point" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="AgentPoint" >
                        <span>Agent</span>
                        <Link to="/WinLucky/agent/point" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="UserPoint" >
                        <span>User</span>
                        <Link to="/WinLucky/user/point" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}


                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINTS TRANSACTION" key="poinTransaction" icon={<MenuFoldOutlined style={iconStyle} />}>
                    <Menu.Item key="PartnerPointin">
                      <span>Transaction In</span>
                      <Link to="/WinLucky/transaction-in" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>
                    <Menu.Item key="PartnerPointout" >
                      <span>Transaction Out</span>
                      <Link to="/WinLucky/transaction-out" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>
                    <Menu.Item key="PointFile" >
                      <span>Point File </span>
                      <Link to="/WinLucky/point-file" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>
                  </Menu.SubMenu>
                )}


                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="BUSSINESS REPORT" key="bussinessMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="BussinessPartner" >
                        <span>Partner</span>
                        <Link to="/WinLucky/partner/bussiness-report" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="BussinessDistributor" >
                        <span>Distributor</span>
                        <Link to="/WinLucky/distributor/bussiness-report" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Bussiness Sub Distributor" >
                        <span>Sub Distributor</span>
                        <Link to="/WinLucky/sub-distributor/bussiness-report" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="BussinessAgent" >
                        <span>Agent</span>
                        <Link to="/WinLucky/agent/bussiness-report" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="BussinessUser" >
                        <span>User</span>
                        <Link to="/WinLucky/user/bussiness-report" onClick={() => toggleCollapsedMobile()} />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}

                {/* {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINT MANAGEMENT" key="pointMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="PartnerPoint" >
                        <span>Partner</span>
                        <Link to="/partner/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="DistributorPoint" >
                        <span>Distributor</span>
                        <Link to="/distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Sub Distributor Point" >
                        <span>Sub Distributor</span>
                        <Link to="/sub-distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="AgentPoint" >
                        <span>Agent</span>
                        <Link to="/agent/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="UserPoint" >
                        <span>User</span>
                        <Link to="/user/point" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )} */}


                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <>
                    <Menu.Item key="GameTransaction" icon={<MenuFoldOutlined style={iconStyle} />}>
                      <span>GAME TRANSACTION</span>
                      <Link to="/WinLucky/game-transaction" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>

                  </>
                )}
                {/* {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <>
                    <Menu.Item key="BussinessReport" icon={<HomeOutlined style={iconStyle} />}>
                      <span>BUSSINESS REPORT </span>
                      <Link to="/bussiness-report" />
                    </Menu.Item>

                  </>
                // )} */}
                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Game" icon={<AuditOutlined style={iconStyle} />}>
                      <span>GAME</span>
                      <Link to="/WinLucky/game" onClick={() => toggleCollapsedMobile()} />
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>

            {
              (isWindowShow && !collapsed)
                ?

                <Layout className="site-layout"
                >
                  <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                    <div className="topbar">
                      <div className="topbarWrapper d-flex justify-content-end " >
                        <div className="topRight" style={{ paddingRight: isWindowShow ? "250px" : 0 }}>
                          <Popover content={content} trigger={['hover', 'click']} placement="bottom">
                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                              <div className="topbar-text">
                                <h6 style={{ fontWeight: '200' }}>{userRole?.toUpperCase()}</h6>
                              </div>
                              <div className="topbarIcons" style={{ marginLeft: 8 }}>
                                <CaretDownOutlined />
                              </div>
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </Header>

                  <Content style={{ marginTop: 60 }}>
                    <Outlet />
                  </Content>
                </Layout>
                :
                <Layout style={{ marginLeft: 0 }}>
                  <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                    <div className="topbar" style={{ height: '100px' }}>
                      <div>
                        <div className="d-flex justify-content-between " style={{ paddingRight: '20px', paddingLeft: '20px', borderBottom: '1px solid rgb(233 233 233)' }}>
                          <h4 style={{ margin: 0, fontWeight: 600 }}>GOALOCAL</h4>
                          <button
                            title="Toggle Menu"
                            className="close-button"
                            onClick={toggleCollapsed}
                          >
                            <CloseOutlined style={{ color: "rgb(0, 0, 0)", fontSize: "20px" }} />
                          </button>
                        </div>
                        <div className="topbarWrapper d-flex justify-content-end" style={{ lineHeight: 0, paddingRight: '55px' }}>
                          <div className="topRight">
                            <Popover content={content} trigger={['hover', 'click']} placement="bottom">
                              <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <div className="topbar-text">
                                  <h6 style={{ fontWeight: '200' }}>{userRole?.toUpperCase()}</h6>
                                </div>
                                <div className="topbarIcons">
                                  <CaretDownOutlined />
                                </div>
                              </div>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Header>

                  <Content style={{ marginTop: 100 }}>
                    <Outlet />
                  </Content>
                </Layout>
            }
          </Layout>

      }
    </>

  );
}

export default Sidebar;
