import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { apiAdminConfig } from "../../utils/api";
import { getRole } from "../../utils/cookie";
import { Table, Input, Typography ,Pagination} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";

const GameTransaction = () => {
    const [gameTransactionList, setGameTransactionList] = React.useState([]);
    const [userRole, setUserRole] = React.useState(getRole());
    const [totalCashIn, setTotalCashIn] = useState(0)
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    const [search, setSearch] = React.useState("");
    const [gameList, setGameList] = useState([]);

    // const [partnerList, setPartnerList] = useState([])
    const [disributorList, setDistributorList] = useState([])
    const [subDisributorList, setSubDistributorList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [totalData ,setTotalData]=useState(0)

    const navigate = useNavigate();


    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const initialValues = {
        startDate: '',
        endDate: '',
        device: '',
        game: '',
        ...((userRole == 'admin' || userRole == 'partner') && { distributor: '', subDistributor: '', agent: '' }),
        ...((userRole == 'distributor') && { subDistributor: '', agent: '' }),
        ...((userRole == 'subDistributor' || userRole === 'superAdmin') && { agent: '' }),
    };

    const getGameList = async () => {
        await apiAdminConfig.get("games/get").then((response) => {
            if (response?.data?.results?.length > 0) {
                setGameList(
                    response.data.results.map((list) => ({
                        label: list?.name ?? '',
                        value: list?.id
                    })) ?? []
                );

            }
        }).catch((error) => {
            console.log("gameListError", error);
        });
    };

    useEffect(() => {
        getGameList();
    }, []);

    useEffect(() => {
        if (userRole == 'admin' || userRole == 'partner') {
            getDistributor();
        }
        if (userRole == 'distributor') {
            getSubDistributor();
        }
        if (userRole == 'subDistributor') {
            getAgent();
        }

    }, [])






    const getSubDistributor = async (id = '') => {
        await apiAdminConfig.get(`users/list?userBy=subDistributor&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getAgent = async (id = '') => {
        await apiAdminConfig.get(`users/list?userBy=agent&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setAgentList(response?.data)
            } else {
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getDistributor = async (id = '') => {
        // const parentId = (id == "" && !!values.admin) ? values.admin : id

        await apiAdminConfig.get(`users/list?userBy=distributor&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setDistributorList(response?.data)
            } else {
                setDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };




    const getGameTransactionList = async (device = '', toDate = '', fromDate = '', id = '', game = '') => {
        setLoading(true);
        try {
            setGameTransactionList([])
            setTotalData(0)
            const url = id ? `games/transactions?search=${search}&device=${device}&toDate=${toDate}&fromDate=${fromDate}&userId=${id}&game=${game}&isPagination=true&page=${currentPage}&limit=${pageSize}` : `games/transactions?search=${search}&device=${device}&toDate=${toDate}&fromDate=${fromDate}&game=${game}&isPagination=true&page=${currentPage}&limit=${pageSize}`
            const response = await apiAdminConfig.get(
                url

            );
            console.log("response",response)
            console.log("response?.metadata?.total",response?.data?.metadata[0]?.total)
            if (response?.status === 200) {
                setGameTransactionList(response?.data?.data);
                setTotalData(response?.data?.metadata[0]?.total)

            }
        } catch (error) {
            console.log("ListError", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getGameTransactionList(); // Initial load
    }, [search,pageSize,currentPage]);

    const columns = [
        {
            title: '#',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {(currentPage - 1) * pageSize + index + 1}
                    </>
                )
            }
        },
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'user Name',
            render: (_, record) => (
                <button style={{ border: 'none', color: 'blue', background: 'none', fontWeight: 500 }} onClick={() => navigate(`/WinLucky/user/view`, { state: { data: record } })}>
                    {record.userName}
                </button>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'Date',
            render: text => text ? moment(text).format('DD-MM-YYYY') : '-',
            width: 250,
        },
        {
            title: 'Before Point',
            dataIndex: 'beforePoints',
            key: 'Before Point',
            width: 150,
            render: text => text || '-',
        },
        {
            title: 'Play',
            dataIndex: 'playPoints',
            key: 'Play',
            render: text => text || '-',
        },
        {
            title: 'Win',
            dataIndex: 'win',
            key: 'Win',
            render: (_, record) => record?.playgame?.winNumber || "0",
        },
        {
            title: 'After Point',
            dataIndex: 'afterPoints',
            key: 'After Point',
            render: text => text || '-',
        },

        {
            title: 'Refer Id',
            dataIndex: 'referId',
            key: 'Refer Id',
            render: text => text || '-',
            render: (_, record) => (
                <button style={{ border: 'none', color: 'blue', background: 'none', fontWeight: 500 }} onClick={() => navigate(`/WinLucky/game-result`, { state: { data: record } })}>
                    {record.playgame?.handId}
                </button>
            ),
        },


    ];
    const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async () => {
            const { device, startDate, endDate, agent, game } = values;
            await getGameTransactionList(device, endDate, startDate, agent, game);
        },
    });
    return (
        <main>

            <Container className="admin_padding">
                <div style={{ display: "flex", justifyContent: "space-between", margin: "14px 0", alignItems: "center", padding: "0 10px" }} className="table-hader-container">
                    <div className=" breadcumb">
                        <h1 > ALL GAME TRANSACTION</h1>
                        <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> /   ALL GAME TRANSACTION</p>
                    </div>

                </div>
                <div className="admin_form" style={{ width: '98%', marginBottom: '20px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#f33120' }}> FILTER</h6>
                    </div>
                    <Row className="admin_add_form">

                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>

                                    {
                                        (userRole == 'admin' || userRole == 'partner') && (
                                            // <Row>
                                            <Col className="mb-3" md={6}>
                                                <Form.Group controlId="validationFormik01">
                                                    <Form.Label>
                                                        Select Distributor
                                                    </Form.Label>
                                                    <Form.Select
                                                        type="text"
                                                        name="distributor"
                                                        value={values.distributor}
                                                        onChange={(e) => {
                                                            setFieldValue("distributor", e.target.value)
                                                            if (e.target.value) {
                                                                getSubDistributor(e.target.value)
                                                            } else {
                                                                setSubDistributorList([])
                                                                setAgentList([])
                                                                setFieldValue("subDistributor", '')
                                                                setFieldValue("agent", '')
                                                            }
                                                        }}
                                                    >
                                                        <option value=''>Select Distributor</option>
                                                        {disributorList && disributorList.length > 0 && disributorList.map((item, index) => {
                                                            return (
                                                                <option value={item?.id} >{item?.userName}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            // </Row>
                                        )
                                    }
                                    {
                                        (userRole == 'admin' || userRole == 'partner' || userRole == 'distributor') &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Sub Distributor
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="subDistributor"
                                                    value={values.subDistributor}
                                                    disabled={(userRole === "admin" || userRole === "partner") && !values.distributor}
                                                    onChange={(e) => {
                                                        setFieldValue("subDistributor", e.target.value)
                                                        if (e.target.value) {
                                                            getAgent(e.target.value)
                                                        } else {
                                                            setAgentList([])
                                                            setFieldValue("agent", '')
                                                        }
                                                    }}
                                                >
                                                    <option value="">Select Sub Distributor</option>
                                                    {subDisributorList && subDisributorList.length > 0 && subDisributorList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                                <Row>


                                    {
                                        (userRole == 'admin' || userRole == 'partner' || userRole == 'distributor' || userRole == 'subDistributor') &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Agent
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="agent"
                                                    value={values.agent}
                                                    disabled={(userRole === "admin" || userRole === "partner" || userRole === "distributor") && !values.subDistributor}
                                                    onChange={(e) => {
                                                        setFieldValue("agent", e.target.value)
                                                    }}
                                                >
                                                    <option value=''>Select Agent</option>
                                                    {agentList && agentList.length > 0 && agentList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }

                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>START DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="startDate"
                                                value={values.startDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik02">
                                            <Form.Label>END DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="endDate"
                                                value={values.endDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>DEVICE</Form.Label>
                                        <div className="d-flex">
                                            <Form.Check
                                                type="radio"
                                                id="devicePC"
                                                name="device"
                                                label="PC"
                                                value="PC"
                                                checked={values.device === 'PC'}
                                                onChange={handleChange}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="deviceMobile"
                                                name="device"
                                                label="MOBILE"
                                                value="MOBILE"
                                                checked={values.device === 'MOBILE'}
                                                onChange={handleChange}
                                                style={{ marginLeft: '10px' }}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="deviceAll"
                                                name="device"
                                                label="ALL"
                                                value="ALL"
                                                checked={values.device === 'ALL'}
                                                onChange={handleChange}
                                                style={{ marginLeft: '10px' }}

                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mb-3" md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Select Game
                                            </Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="game"
                                                value={values.game}
                                                onChange={(e) => {
                                                    setFieldValue("game", e.target.value)
                                                }}
                                            >
                                                <option value=''>Select Game</option>
                                                {gameList && gameList.length > 0 && gameList.map((item, index) => {
                                                    return (
                                                        <option value={item?.value} >{item?.label}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div style={{ display: "flex", justifyContent: "center" }} className="footer-btns">
                                    <Button type="submit" className="input_file_button">
                                        SUBMIT
                                    </Button>
                                </div>
                            </Form>
                        </Col>

                    </Row>
                </div>

                <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: 'rgb(56 39 221)' }}>ALL GAME TRANSACTION</h6>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div className="page-size">
                            <span className="fw-bold">Show</span>
                            <select onChange={(e) => {setPageSize(e.target.value);setCurrentPage(1)}}
                                value={pageSize} className="selecte-page-size">
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                            <span className="fw-bold">entries</span>
                        </div>

                        <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                }}
                            />
                        </div>

                    </div>
                    <div className="table-container" style={{ margin: "20px" }}>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        <Table  bordered dataSource={gameTransactionList} columns={columns} scroll={{ x: 525 }}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                total: totalData,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                },
                                showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                            }}

                            onChange={handleTableChange}

                        />

                    </div>
                </div>

                {/* <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                    <div className="detail-heading">
                        <h6 className="text-white" style={{ backgroundColor: 'rgb(56 39 221)' }}>ALL GAME TRANSACTION</h6>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div className="page-size">
                            <span className="fw-bold">Show</span>
                            <select
                                onChange={(e) => setPageSize(e.target.value)}
                                value={pageSize}
                                className="selecte-page-size"
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                            </select>
                            <span className="fw-bold">entries</span>
                        </div>

                        <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                }}
                            />
                        </div>
                    </div>

                    <div className="table-container" style={{ margin: "20px" }}>
                    {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        <Table
                            bordered
                            dataSource={gameTransactionList.slice((currentPage - 1) * pageSize, currentPage * pageSize)} // Slice the data based on current page
                            columns={columns}
                            scroll={{ x: 525 }}
                            pagination={false} // Disable internal pagination of the table
                            onChange={handleTableChange}
                        />

                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={gameTransactionList.length}
                            showSizeChanger={false} // Hide the size changer dropdown (already handled above)
                            onChange={(page, pageSize) => {
                                setCurrentPage(page); // Set the current page
                            }}
                            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                    </div>
                </div> */}
            </Container>
        </main >
    );
};

export default GameTransaction;

